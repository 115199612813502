.userlist-thead {
  background-color: #f0f2f7;
  height: 48px;
  top: 0;
  position: sticky;
  z-index: 1;
}

.userlist-table {
  border-top-left-radius: calc(var(--spacer) * 1px);
  border-top-right-radius: calc(var(--spacer) * 1px);
}

.userlist-thead tr {
  background-color: #f0f2f7;
}

.userlist-table th {
  text-align: left;
  padding-left: 12px;
}

.userlist-tr-td {
  background-color: white;
  padding-left: 8px;
  cursor: pointer;
  height: 48px;
}

.userlist-tr-td:hover {
  background-color: whitesmoke;
}

.userlist-td {
  padding-left: 20px;
  margin-bottom: 10px;
  border-bottom: #f0f2f7 solid 1px;
  font-size: 13px;
}

.userlist-td-role {
  border-bottom: #f0f2f7 solid 1px;
  font-size: 13px;
  padding-left: 8px;
}

.userlist-td-icon {
  padding-left: 9px;
  border-bottom: #f0f2f7 solid 1px;
  text-align: center;
}

.datagrid-th {
  background-color: #F5F6FA;
}

/* toggle switch */

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F0F2F7;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  /* background-color: white; */
  background-color: #fafafa;
  box-shadow: 0px 1px 1px #0004;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  /* background-color: #24A148; */
  background-color: #F0F2F7;

}

input:focus+.slider {
  box-shadow: 0 0 1px #24A148;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: #24A148;
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
  background-color: #F0F2F7;
}

.slider.round:before {
  border-radius: 50%;
}

.tableHeaderButton {
  color: var(--secondaryColor);
  background-color: var(--primaryColor);
  border: unset;
  cursor: pointer;
  height: 36px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
}
.userlist-upload-button {
  color: var(--primaryColor);
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor);
}
.userlist-upload-image {
  margin-right: 10px;
}
.userlist-upload-button:hover{
  background-color: var(--highlightColor); 
}

.hover-circle {
  position: relative;
}

.hover-circle img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px; 
  height: 24px;
}

.hover-circle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.hover-circle:hover::after {
  opacity: 1;
}

